<template>
    <div style="background:#FCFCFC">
        <div class="container position-relative">
            <router-link
                :to="{ name: 'MyOrder' }"
                class="position-absolute"
                style="left:2rem;opacity: 0.5; color: black;text-decoration:none"
            >
                <span class="triangle"></span> back
            </router-link>
            <div
                class="row d-flex flex-column justify-content-center flex-lg-row mx-xl-5 py-5"
                v-if="order"
            >
                <div
                    class="bg-white rounded shadow-sm p-2 col-lg-8 col-xl-6 col-12 mb-3"
                >
                    <div
                        class="text-center rounded p-1"
                        style="background: #3cb207; color: #fff"
                    >
                        <font-awesome-icon
                            icon="check-circle"
                            style="
                            width: 1.5rem !important;
                            height: 1.5rem !important;
                            margin-top: 0.5rem;
                        "
                        />
                        <p>Ordered Successfully</p>
                        <p>
                            Order Number:
                            <span class="text-decoration-underline">{{
                                order.order_no
                            }}</span>
                        </p>
                        <p class="fw-bold">
                            {{ addCommaForPrice(totalCost) }} MMK
                        </p>
                    </div>

                    <div id="accordion" role="tablist" class="my-3">
                        <div class="card">
                            <div
                                class="card-header bg-white border-bottom-0"
                                role="tab"
                                id="headingOne"
                            >
                                <h5
                                    data-bs-toggle="collapse"
                                    href="#collapseOne"
                                    aria-expanded="true"
                                    aria-controls="collapseOne"
                                    class="cursor"
                                    style="font-weight: 600; font-size: 1.4rem"
                                >
                                    Order
                                </h5>
                                <div
                                    id="collapseOne"
                                    class="collapse show"
                                    role="tabpanel"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordion"
                                >
                                    <p class="m-0">{{ order.order_no }}</p>

                                    <table class="w-100">
                                        <tbody>
                                            <tr class="">
                                                <th class="py-1">Total</th>
                                                <td class="py-1 text-end">
                                                    {{
                                                        addCommaForPrice(
                                                            order.net_total,
                                                        )
                                                    }}
                                                    MMK
                                                </td>
                                            </tr>
                                            <tr v-if="order.tax">
                                                <th
                                                    class="py-1"
                                                    v-if="order.tax"
                                                >
                                                    Tax({{ order.tax }}%)
                                                </th>
                                                <!-- <td class="py-1">{{ discount }} MMK</td> -->
                                                <td class="py-1 text-end">
                                                    {{ taxPrice }} MMK
                                                </td>
                                            </tr>
                                            <tr v-else>
                                                <th class="py-1">
                                                    Tax(5%)
                                                </th>
                                                <!-- <td class="py-1">{{ discount }} MMK</td> -->
                                                <td class="py-1 text-end">
                                                    5000 MMK
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="py-1">
                                                    Delivery Fees
                                                </th>
                                                <!-- <td class="py-1">{{ totalTaxes }} MMK</td> -->
                                                <td class="py-1 text-end">
                                                    {{
                                                        addCommaForPrice(
                                                            order.delivery_fee,
                                                        )
                                                    }}
                                                    MMK
                                                    <!-- 0 MMK -->
                                                </td>
                                            </tr>
                                            <tr v-if="order.discount">
                                                <th class="py-1">
                                                    Discount Ticket
                                                </th>
                                                <!-- <td class="py-1">{{ totalTaxes }} MMK</td> -->

                                                <td class="py-1 text-end">
                                                    {{
                                                        addCommaForPrice(
                                                            order.discount,
                                                        )
                                                    }}
                                                    MMK
                                                </td>
                                                <!-- <td class="py-1 text-end" v-else>
                                        0 MMK
                                    </td> -->
                                            </tr>
                                            <tr v-else>
                                                <th class="py-1">
                                                    Discount Ticket
                                                </th>
                                                <!-- <td class="py-1">{{ totalTaxes }} MMK</td> -->

                                                <td class="py-1 text-end">
                                                    <!-- {{
                                                        selectedRedeem.promo_price
                                                    }}
                                                    MMK -->0 MMK
                                                </td>
                                                <!-- <td class="py-1 text-end" v-else>
                                        0 MMK
                                    </td> -->
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th class="py-1 fw-bold">
                                                    Grand Total:
                                                </th>
                                                <td
                                                    class="
                                                    m-0
                                                    fw-bold
                                                    fs-5
                                                    text-end
                                                "
                                                    style="color: #0c8410"
                                                >
                                                    {{
                                                        addCommaForPrice(
                                                            totalCost,
                                                        )
                                                    }}
                                                    MMK
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>

                            <div
                                id="collapseOne"
                                class="collapse show"
                                role="tabpanel"
                                aria-labelledby="headingOne"
                                data-parent="#accordion"
                            >
                                <div
                                    class="
                                    card-body
                                    border-top
                                    pb-3
                                "
                                    v-for="(opv,
                                    index) in order.orderproductvariants"
                                    :key="index"
                                >
                                    <div
                                        class="d-flex flex-column-reverse flex-md-row justify-content-between align-items-center"
                                    >
                                        <div>
                                            <p
                                                class="p-0 m-0"
                                                style="font-size: 14px"
                                            >
                                                {{ opv.product.title }}
                                            </p>
                                            <div class="d-flex gap-2">
                                                <p
                                                    :class="[
                                                        'p-0 m-0 fw-bold ',
                                                        opv.productvariant
                                                            .final_price >
                                                        opv.total_cost
                                                            ? 'text-decoration-line-through'
                                                            : '',
                                                    ]"
                                                >
                                                    {{
                                                        addCommaForPrice(
                                                            opv.productvariant
                                                                .final_price,
                                                        )
                                                    }}
                                                    MMK
                                                </p>
                                            </div>
                                            <p
                                                class="
                                            p-0
                                            m-0
                                            
                                        "
                                                style="font-size: 14px"
                                            >
                                                {{
                                                    parseInt(
                                                        opv.total_ordered_products,
                                                    )
                                                }}
                                                X
                                                {{
                                                    addCommaForPrice(
                                                        opv.total_cost,
                                                    )
                                                }}
                                                MMK
                                            </p>
                                            <p
                                                class="p-0 pt-2 m-0"
                                                style="font-size: 14px"
                                            >
                                                {{ opv.product.goldtype.name }}
                                            </p>
                                            <!-- {{ opv.product.stones }} -->
                                            <p
                                                class="
                                                p-0
                                                m-0
                                                d-flex
                                                align-items-center
                                                gap-2
                                            "
                                                style="font-size: 14px"
                                            >
                                                <span
                                                    v-for="stone in opv.product
                                                        .stones"
                                                    :key="stone.id"
                                                >
                                                    <span
                                                        >{{ stone.name }} x
                                                        {{
                                                            stone.total_quantity
                                                        }}</span
                                                    >
                                                    <!-- <span>Diamond x 34</span> -->
                                                </span>
                                            </p>
                                            <p
                                                class="p-0 m-0"
                                                style="font-size: 14px"
                                            >
                                                {{ opv.name }}
                                            </p>
                                        </div>
                                        <div>
                                            <img
                                                class="rounded shadow-sm"
                                                :src="
                                                    opv.product.photos[0]
                                                        .thumbnail_url
                                                "
                                                alt="product"
                                                style="width:14.75rem;height:14.75rem"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordion" role="tablist" class="my-3">
                        <div class="card">
                            <div
                                class="card-header bg-white border-bottom-0"
                                role="tab"
                                id="headingTwo"
                            >
                                <h5
                                    data-bs-toggle="collapse"
                                    href="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                    class="cursor"
                                    style="font-weight: 600; font-size: 30px"
                                >
                                    <font-awesome-icon
                                        icon="credit-card"
                                        style="
                                        color: #3cb207;
                                        margin-right: 0.3rem;
                                        font-size: large;
                                        margin-bottom: 0.2rem;
                                    "
                                    />Payment Method
                                </h5>
                                <div
                                    id="collapseTwo"
                                    class="collapse show"
                                    role="tabpanel"
                                    aria-labelledby="headingTwo"
                                    data-parent="#accordion"
                                >
                                    <p class="m-0">
                                        1. {{ payment[0].method_1 }}
                                    </p>

                                    <p class="m-0">
                                        2. {{ payment[0].method_2 }}
                                    </p>
                                </div>
                            </div>

                            <div
                                id="collapseTwo"
                                class="collapse show"
                                role="tabpanel"
                                aria-labelledby="headingTwo"
                                data-parent="#accordion"
                            >
                                <div class="d-flex flex-wrap">
                                    <div
                                        class="
                                        d-flex
                                        flex-column
                                        align-items-center
                                        border
                                        py-2
                                    "
                                        style="width: 50%"
                                        v-for="(bank, index) in banks"
                                        :key="index"
                                    >
                                        <img
                                            v-show="bank.name === 'KBZ BANK'"
                                            src="../../assets/img/banks/kbz.png"
                                            alt="kbz bank"
                                            style="width: 6rem; height: 2rem"
                                        />
                                        <img
                                            v-show="bank.name === 'AYA BANK'"
                                            src="../../assets/img/banks/aya.png"
                                            alt="kbz bank"
                                            style="width: 4rem; height: 2rem"
                                        />
                                        <img
                                            v-show="bank.name === 'YOMA BANK'"
                                            src="../../assets/img/banks/yoma.png"
                                            alt="kbz bank"
                                            style="width: 2rem; height: 2rem"
                                        />
                                        <img
                                            v-show="bank.name === 'CB BANK'"
                                            src="../../assets/img/banks/cb.png"
                                            alt="kbz bank"
                                            style="width: 4rem; height: 2rem"
                                        />
                                        <img
                                            v-show="bank.name === 'KPAY'"
                                            src="../../assets/img/banks/kpay.png"
                                            alt="kbz bank"
                                            style="width: 2rem; height: 2rem"
                                        />

                                        <p class="m-0">
                                            {{ bank.number }}
                                        </p>
                                        <p class="m-0">
                                            {{ bank.account_name }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span class="d-flex justify-content-center"
                            >Something wrong? call
                            <span class="fw-bold">{{
                                payment[0].hot_line
                            }}</span></span
                        >
                    </div>
                </div>

                <div class="col-12 col-md-6 col-lg-4 col-xl-3">
                    <div
                        class="bg-white rounded shadow-sm p-1"
                        style="height: max-content"
                    >
                        <div
                            class="text-start bg-white p-3 rounded"
                            v-if="order.status === 2"
                        >
                            <h5 style="font-weight: 600; font-size: 1.4rem">
                                <font-awesome-icon
                                    icon="paper-plane"
                                    style="
                                    color: #3cb207;
                                    margin-right: 0.3rem;
                                    font-size: large;
                                    margin-bottom: 0.2rem;
                                "
                                />Send Bank Receipt
                            </h5>
                            <span
                                class="text-success"
                                v-show="showUploadMessage"
                                >Uploaded Successfully</span
                            >
                            <button
                                class="payment_photo text-center cursor"
                                @click="$refs.file.click()"
                            >
                                <input
                                    @change="uploadPhoto"
                                    class="d-none"
                                    ref="file"
                                    type="file"
                                    accept="image/x-png,image/gif,image/jpeg,image/jpg"
                                />
                                <font-awesome-icon icon="plus-circle" />
                                Choose photo
                            </button>
                            <p class="text-center">- or -</p>
                            <p class="text-center">
                                Send via Viber
                                <font-awesome-icon
                                    :icon="['fab', 'viber']"
                                    style="color: #7360f2"
                                />
                                {{ payment[0].viber }}
                            </p>
                        </div>
                        <img
                            v-if="
                                order.receipts.length > 0 &&
                                    order.status != 1 &&
                                    order.status != 2
                            "
                            :src="
                                order.receipts[order.receipts.length - 1]
                                    .file_url
                            "
                            :alt="order.id"
                            class="card-img-top"
                        />
                        <div class="bg-white p-3 mt-3 rounded">
                            <div
                                class="d-flex pb-3"
                                v-for="(history,
                                index) in order.prepareHistories"
                                :key="index"
                            >
                                <span
                                    :class="
                                        history.checked
                                            ? 'timeline'
                                            : 'no-timeline'
                                    "
                                ></span>
                                <li class="event">
                                    <!-- <p
                                        class="m-0 fw-bold"
                                        :class="
                                            status.history ? '' : 'order-status'
                                        "
                                    >
                                        {{ status.name }}
                                    </p> -->
                                    <p
                                        class="m-0"
                                        :class="
                                            history.checked ? 'fw-bold' : ''
                                        "
                                    >
                                        {{ history.status }}
                                    </p>
                                    <p class="m-0">
                                        <!-- {{
                                            status.history
                                                ? formatTime(
                                                      status.history.created_at,
                                                  )
                                                : ''
                                        }}
                                        {{
                                            status.history
                                                ? formatDate(
                                                      status.history.created_at,
                                                  )
                                                : ''
                                        }} -->
                                        {{ formatTime(history.created_at) }}
                                        {{ formatDate(history.created_at) }}
                                    </p>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import OrderDetail from '../../graphql/queries/order/OrderDetail.gql';
import OrderStatus from '../../graphql/queries/order/OrderStatus.gql';
import Payment from '../../graphql/queries/payment/payment.gql';
import Bank from '../../graphql/queries/bank/bank.gql';
import CommaPrice from '@/mixins/CommaPrice';
import UploadReceipt from '../../graphql/mutations/order/UploadReceipt.gql';
import { mapGetters } from 'vuex';

export default {
    mixins: [CommaPrice],
    data: () => ({
        showUploadMessage: false,
        statuses: [],
        payment: {},
        banks: [],
    }),
    created() {
        // reload page once
        if (localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
        } else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
        //
        this.getOrder();
        this.paymentMethod();
        this.getBanks();
        // this.getStatus();
    },
    computed: {
        ...mapGetters({
            order: 'order/getOrder',
            selectedRedeem: 'home/getSelectedRedeem',
        }),
        taxPrice() {
            // return this.netTotal * (this.totalTaxes / 100);
            let taxRate = this.order.tax / 100;
            return Math.ceil((this.order.total * taxRate) / 50) * 50;
        },
        totalCost() {
            return this.order.total + this.taxPrice + this.order.delivery_fee;
        },
    },
    methods: {
        getOrder() {
            this.loading = true;
            this.$apollo
                .query({
                    query: OrderDetail,
                    variables: {
                        id: this.$route.query.slug,
                    },
                })
                .then(response => {
                    // this.order = response.data.order;
                    this.$store.commit('order/ADD_ORDER', response.data.order);
                    this.loading = false;
                    // prepare status
                    this.$apollo
                        .query({
                            query: OrderStatus,
                        })
                        .then(response => {
                            this.statuses[0] = response.data.status[0];
                            this.statuses[1] = response.data.status[1];
                            this.statuses[2] = response.data.status[4];
                            this.statuses[3] = response.data.status[5];
                            this.statuses[4] = response.data.status[6];
                            this.statuses[5] = response.data.status[3];
                            this.statuses[6] = response.data.status[2];
                            this.statuses = this.statuses.filter(sts => {
                                let found = this.order.order_histories.find(
                                    od => od.next === sts.name,
                                );
                                if (found) {
                                    sts.history = found;
                                    sts.show = true;
                                }
                                return sts;
                            });
                        })
                        .catch(error => {
                            console.log(error.response);
                        });
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        // getStatus() {
        //     this.$apollo
        //         .query({
        //             query: OrderStatus,
        //         })
        //         .then(response => {
        //             this.statuses[0] = response.data.status[0];
        //             this.statuses[1] = response.data.status[1];
        //             this.statuses[2] = response.data.status[4];
        //             this.statuses[3] = response.data.status[5];
        //             this.statuses[4] = response.data.status[6];
        //             this.statuses[5] = response.data.status[3];
        //             this.statuses[6] = response.data.status[2];
        //             this.statuses = this.statuses.filter(sts => {
        //                 let found = this.order.order_histories.find(
        //                     od => od.next === sts.name,
        //                 );
        //                 if (found) {
        //                     sts.history = found;
        //                     sts.show = true;
        //                 }
        //                 return sts;
        //             });
        //         })
        //         .catch(error => {
        //             console.log(error.response);
        //         });
        // },
        formatDate(created_at) {
            return !created_at
                ? ''
                : new Date(created_at).toLocaleDateString('en-us', {
                      timeZone: 'Asia/Rangoon',
                      year: 'numeric',
                      weekday: 'short',
                      month: 'short',
                      day: 'numeric',
                  });
        },
        formatTime(created_at) {
            return !created_at
                ? ''
                : new Date(created_at).toLocaleTimeString('en-us', {
                      timeZone: 'Asia/Rangoon',
                      hour12: true,
                      hour: 'numeric',
                      minute: 'numeric',
                  }) ?? '';
        },
        uploadPhoto({ target }) {
            this.$apollo
                .mutate({
                    mutation: UploadReceipt,
                    variables: {
                        order_id: this.order.id,
                        image: target.files[0],
                    },
                    // update: (store, { data: { uploadPhoto } }) => {
                    // const data = store.readQuery({ query: ALL_PHOTOS });
                    // data.allPhotos.push(uploadPhoto);
                    // store.writeQuery({ query: ALL_PHOTOS, data });
                    // },
                })
                .then(response => {
                    console.log(response);
                    this.showUploadMessage = true;
                    this.$router.go(this.$router.currentRoute);
                })
                .catch(error => {
                    console.log(error.message);
                });
        },
        getBanks() {
            this.$apollo
                .query({
                    query: Bank,
                })
                .then(response => {
                    console.log(response.data);
                    this.banks = response.data.bank;
                })
                .catch(error => {
                    console.log(error.response);
                });
        },
        paymentMethod() {
            this.$apollo
                .query({
                    query: Payment,
                })
                .then(response => {
                    console.log(response.data.payment);
                    this.payment = response.data.payment;
                })
                .catch(err => {
                    console.log(err.response);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
[data-bs-toggle='collapse']:after {
    content: ' ';
    display: inline-block;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-bottom: 3px solid #333;
    border-right: 3px solid #333;
    height: 10px;
    width: 10px;
    position: absolute;
    right: 1rem;
    margin: 0.5rem 0 0 0;
    transform: rotate(45deg);
    transition: all linear 0.25s;
}
[data-bs-toggle='collapse'].collapsed:after {
    transform: rotate(-45deg);
}
.payment_photo {
    border: 1px dashed #855e0a;
    color: #855e0a;
    background: transparent
        linear-gradient(106deg, #fffddd00 0%, #fffdddbf 100%) 0% 0% no-repeat
        padding-box;
    border: 1px dashed #855e0a;
    border-radius: 10px;
    opacity: 1;
    height: auto;
    width: 100%;
    padding: 2rem 0.5rem;
}
.timeline {
    border-left: 1px solid #3cb207;
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 0 0.5rem;
    list-style: none;
    text-align: left;
    max-width: 100%;
    margin-bottom: -1rem;
    &:after {
        content: ' ';
        background: #fff;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 4px solid #3cb207;
        top: 0.3rem;
        left: -0.5rem;
        width: 1rem;
        height: 1rem;
        z-index: 400;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}
.event {
    list-style: none;
}
.order-status {
    color: #888;
}
.no-timeline {
    position: relative;
    line-height: 1.4em;
    font-size: 1.03em;
    padding: 0 0.5rem;
    list-style: none;
    text-align: left;
    max-width: 100%;
    margin-bottom: -1rem;
    opacity: 0.5;
    &:after {
        content: ' ';
        background: #fff;
        display: inline-block;
        position: absolute;
        border-radius: 50%;
        border: 2px solid #888;
        top: 0.3rem;
        left: -0.5rem;
        width: 1rem;
        height: 1rem;
        z-index: 400;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    }
}
.triangle::after {
    position: absolute;
    top: 50%;
    right: 115%;
    margin-top: -6px;
    content: ' ';
    width: 0;
    height: 0;
    border-left: 0.4rem solid transparent;
    border-right: 0.4rem solid black;
    border-bottom: 0.4rem solid transparent;
    border-top: 0.4rem solid transparent;
}
</style>
