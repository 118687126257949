<template>
    <order-detail />
</template>

<script>
import OrderDetail from '../../components/profile/OrderDetail.vue';

export default {
    components: {
        'order-detail': OrderDetail,
    },
};
</script>
